<template>
  <v-app id="app">
    <!-- 导航栏 -->
    <TopNavBar></TopNavBar>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <!-- 内容 -->
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <!-- 页脚 -->
    <Footer></Footer>
    <!-- 返回顶部 -->
    <BackTop></BackTop>
    <!-- 搜索模态框 -->
    <searchModel></searchModel>
    <!-- 登录模态框 -->
    <LoginModel></LoginModel>
    <!-- 注册模态框 -->
    <RegisterModel></RegisterModel>
    <!-- 忘记密码模态框 -->
    <ForgetModel></ForgetModel>
    <!-- 绑定邮箱模态框 -->
    <EmailModel></EmailModel>
    <!-- 音乐播放器 -->
    <AplayerModel></AplayerModel>

    <!-- 聊天室 -->
    <!--    <ChatRoom></ChatRoom>-->
  </v-app>
</template>

<script>
import TopNavBar from "./components/layout/TopNavBar.vue";
import SideNavBar from "./components/layout/SideNavBar.vue";
import Footer from "./components/layout/Footer.vue";
import BackTop from "./components/BackTop.vue";
import searchModel from "./components/model/SearchModel.vue";
import LoginModel from "./components/model/LoginModel.vue";
import RegisterModel from "./components/model/RegisterModel.vue";
import ForgetModel from "./components/model/ForgetModel.vue";
import EmailModel from "./components/model/EmailModel.vue";
import AplayerModel from "./components/model/AplayerModel.vue";
// import ChatRoom from "./components/ChatRoom";
export default {
  components: {
    TopNavBar,
    SideNavBar,
    Footer,
    BackTop,
    searchModel,
    LoginModel,
    RegisterModel,
    ForgetModel,
    EmailModel,
    AplayerModel
    //ChatRoom
  }
};
</script>

import { getToken, setToken, removeToken } from "@/utils/auth";
import { userLogin,userLogout } from "@/api/login";
import {setEmail, updateUserInfo} from "@/api/user";
import Toast from "@/components/toast";
const user = {
  state: {
    token: getToken(),
    loginUrl: "",
    userId: '',
    avatar: '',
    nickname: '',
    intro: '',
    webSite: '',
    loginType: '',
    email: '',
    articleLikeSet: [],
    commentLikeSet: [],
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_ROLE_LIST: (state, roleList) => {
      state.roleList = roleList;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_INTRO: (state, intro) => {
      state.intro = intro;
    },
    SET_WEBSITE: (state, webSite) => {
      state.webSite = webSite;
    },
    SET_LOGIN_TYPE: (state, loginType) => {
      state.loginType = loginType;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_ARTICLE_LIKE_SET: (state, articleLikeSet) => {
      state.articleLikeSet = articleLikeSet?articleLikeSet:[];
    },
    SET_COMMENT_LIKE_SET: (state, commentLikeSet) => {
      state.commentLikeSet = commentLikeSet?commentLikeSet:[];
    },
    SET_LOGIN_USER:(state, user)=> {
      setToken(user.token);
      state.token = user.token;
      state.userId = user.userInfoId;
      state.avatar = user.avatar;
      state.nickname = user.nickname;
      state.intro = user.intro;
      state.webSite = user.webSite;
      state.loginType = user.loginType;
      state.email = user.email;
      state.articleLikeSet = user.articleLikeSet?user.articleLikeSet:[];
      state.commentLikeSet = user.commentLikeSet?user.commentLikeSet:[];
    },
    SET_LOGOUT:(state) =>{
      state.token = '';
      state.userId = '';
      state.avatar = '';
      state.nickname = '';
      state.intro = '';
      state.webSite = '';
      state.loginType = '';
      state.email = '';
      state.articleLikeSet = [];
      state.commentLikeSet = [];
      removeToken();
    },
    SET_LOGIN_URL: (state, url) => {
      state.loginUrl = url;
    },
  },
  actions: {
    Login({ commit }, loginForm) {
      const username = loginForm.username.trim()
      const password = loginForm.password
      const code = loginForm.code
      const uuid = loginForm.uuid
      return new Promise((resolve, reject) => {
        userLogin(username, password, code, uuid).then(response => {
          const user = response.data;
          commit("SET_LOGIN_USER", user);
          resolve()
        }).catch(error => {
          reject(error)
        });
      })
    },
    // 退出系统
    Logout({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        userLogout().then(() => {
          commit('SET_LOGOUT');
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    SaveLoginUrl({ commit }, url) {
      commit("SET_LOGIN_URL", url);
    },
    SaveEmail({ commit }, user) {
      return new Promise((resolve, reject) => {
        setEmail(user).then(() => {
          commit("SET_EMAIL", user.email);
        })
      })

    },
    UpdateAvatar({ commit }, avatar) {
      commit("SET_AVATAR", avatar);
    },
    UpdateUserInfo({ commit }, user) {
      return new Promise((resolve, reject) => {
        updateUserInfo(user).then(() => {
          commit("SET_NICKNAME", user.nickname);
          commit("SET_INTRO", user.intro);
          commit("SET_WEBSITE", user.webSite);
        }).catch(error => {
          reject(error)
        })
      })

    },
    ArticleLike({ commit,dispatch,state }, articleId) {
      var articleLikeSet = state.articleLikeSet;
      if (articleLikeSet.indexOf(articleId) !== -1) {
        articleLikeSet.splice(articleLikeSet.indexOf(articleId), 1);
      } else {
        articleLikeSet.push(articleId);
      }
      commit("SET_ARTICLE_LIKE_SET", articleLikeSet);
    },
    CommentLike({ commit,dispatch,state }, commentId) {
      var commentLikeSet = state.commentLikeSet;
      if (commentLikeSet.indexOf(commentId) !== -1) {
        commentLikeSet.splice(commentLikeSet.indexOf(commentId), 1);
      } else {
        commentLikeSet.push(commentId);
      }
      commit("SET_COMMENT_LIKE_SET", commentLikeSet);
    }
  }
};

export default user;

<template>
  <v-footer app padless absolute v-if="!this.isMessage">
    <div class="footer-wrap">
      <div>©2023<!-- - {{ new Date().getFullYear() }}--> By NewYoung</div>
      <div>
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402013744">
          <img src="../../assets/img/gongan.png" style="float:left;" alt="" />
          <span>京公网安备 11011402013744号</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          <span>京ICP备2023008440号-1</span>
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  computed: {
    isMessage() {
      return this.$route.path == "/message";
    }
  }
};
</script>

<style scoped>
.footer-wrap {
  width: 100%;
  line-height: 2;
  position: relative;
  padding: 40px 20px;
  color: #eee;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(-45deg, #ee7752, #ce3e75, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: Gradient 10s ease infinite;
}
.footer-wrap a {
  color: #eee !important;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin-right: 10px;
}
.footer-wrap a span {
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
}
@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>

<template>
  <div>
    <canvas ref="fireworksCanvas" class="message-canvas"></canvas>
    <!-- banner -->
    <div class="message-banner">

      <!-- 弹幕输入框 -->
      <div class="message-container">
        <h1 class="message-title">留言板</h1>
        <div class="animated fadeInUp message-input-wrapper">
          <input v-model="messageContent" @click="show = true" @keyup.enter="addToList" placeholder="说点什么吧"/>
          <button class="ml-3 animated bounceInLeft" @click="addToList" v-show="show">发送</button>
        </div>
      </div>
      <!-- 弹幕列表 -->
      <div class="barrage-container">
        <vue-baberrage :barrageList="barrageList"  :isShow="barrageIsShow" :lanesCount="lanesCount" :loop="barrageLoop" :messageHeight="messageHeight" >
        </vue-baberrage>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageList, saveMessage } from "@/api/messages";
import {isEmpty, isHttp} from "@/utils/validate";
export default {
  mounted() {
    this.initializeFireworks()
    this.listMessage();
  },
  data() {
    return {
      show: false,
      messageContent: "",
      barrageList: [],
      barrageIsShow: true, //是否展示弹幕
      barrageLoop: true, //是否循环播放
      messageHeight: 75, //消息高度
      lanesCount: 10 //泳道数量
    };
  },
  methods: {
    addToList() {
      if (isEmpty(this.messageContent.trim())) {
        this.$toast({ type: "error", message: "留言不能为空" });
        return false;
      }
      const userAvatar = this.$store.state.user.avatar? this.$store.state.user.avatar : "https://s2.loli.net/2023/05/26/qXTQ98fVKdte1a7.png";
      const userNickname = this.$store.state.user.nickname ? this.$store.state.user.nickname : "游客";
      const message = {
        avatar: userAvatar,
        nickname: userNickname,
        messageContent: this.messageContent,
        time: Math.floor(Math.random() * (10 - 7)) + 7
      };

      this.messageContent = "";
      saveMessage(message).then(res=>{
        if(res.flag){
            let barrage ={
                id: res.data,
                avatar: isHttp(message.avatar)? message.avatar : 'https://www.talk-ny.cn' + message.avatar,
                msg: message.messageContent,
                time: message.time
            }
            this.barrageList.push(barrage);
        }
      });
    },
    listMessage() {
      this.barrageList =[];
      getMessageList().then(res => {
        if (res.flag) {
          res.data.forEach(item => {
              let barrage ={
                  id: item.id,
                  avatar: isHttp(item.avatar)? item.avatar : 'https://www.talk-ny.cn' + item.avatar,
                  msg: item.messageContent,
                  time: item.time
              }
              this.barrageList.push(barrage)
          })
        }
      });
    },
    initializeFireworks() {
      const canvas = this.$refs.fireworksCanvas;
      const ctx = canvas.getContext("2d");

      // Set canvas size
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // Handle resize
      window.addEventListener("resize", () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      });

      const fireworks = [];
      const colors = ["#FF5733", "#33FF57", "#5733FF", "#FFFF33", "#33FFFF", "#FF33FF", "#FF9933", "#3399FF"];

      // Firework class
      class Firework {
        constructor(startX, startY, endX, endY) {
          this.startX = startX;
          this.startY = startY;
          this.x = startX;
          this.y = startY;
          this.endX = endX;
          this.endY = endY;
          this.color = colors[Math.floor(Math.random() * colors.length)];
          this.particles = [];
          this.exploded = false;
          this.alpha = 1; // Opacity
          this.speed = Math.random() * 3 + 2;
          this.angle = Math.atan2(endY - startY, endX - startX);
        }

        // Update firework position or handle explosion
        update() {
          if (!this.exploded) {
            // Firework is moving toward its target
            const dx = Math.cos(this.angle) * this.speed;
            const dy = Math.sin(this.angle) * this.speed;
            this.x += dx;
            this.y += dy;

            // Check if it reached its target
            if (Math.abs(this.x - this.endX) < 3 && Math.abs(this.y - this.endY) < 3) {
              this.exploded = true;
              this.createParticles();
            }
          } else {
            // Update particles if exploded
            this.particles.forEach((particle, index) => {
              particle.update();
              if (particle.alpha <= 0) {
                this.particles.splice(index, 1);
              }
            });

            // Remove firework when all particles disappear
            if (this.particles.length === 0) {
              fireworks.splice(fireworks.indexOf(this), 1);
            }
          }
        }

        // Draw firework or particles
        draw() {
          if (!this.exploded) {
            // Draw the firework trail
            ctx.beginPath();
            ctx.arc(this.x, this.y, 3, 0, Math.PI * 2);
            ctx.fillStyle = this.color;
            ctx.fill();
          } else {
            // Draw particles
            this.particles.forEach((particle) => particle.draw());
          }
        }

        // Create particles for explosion
        createParticles() {
          for (let i = 0; i < 100; i++) {
            const angle = Math.random() * Math.PI * 2;
            const speed = Math.random() * 4 + 1;
            const particleColor = colors[Math.floor(Math.random() * colors.length)];
            this.particles.push(
              new Particle(
                this.x,
                this.y,
                Math.cos(angle) * speed,
                Math.sin(angle) * speed,
                particleColor
              )
            );
          }
        }
      }

      // Particle class
      class Particle {
        constructor(x, y, speedX, speedY, color) {
          this.x = x;
          this.y = y;
          this.speedX = speedX;
          this.speedY = speedY;
          this.color = color;
          this.radius = Math.random() * 3 + 1;
          this.alpha = 1; // Opacity
          this.decay = Math.random() * 0.015 + 0.005;
        }

        update() {
          this.x += this.speedX;
          this.y += this.speedY;
          this.alpha -= this.decay;
        }

        draw() {
          ctx.save();
          ctx.globalAlpha = this.alpha;
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
          ctx.fillStyle = this.color;
          ctx.fill();
          ctx.restore();
        }
      }

      // Create random fireworks
      function createRandomFirework() {
        const startX = Math.random() * canvas.width; // Random bottom position
        const startY = canvas.height; // Always start from the bottom
        const endX = Math.random() * canvas.width;
        const endY = Math.random() * canvas.height / 2; // End somewhere in the top half
        fireworks.push(new Firework(startX, startY, endX, endY));
      }

      // Animation loop
      function animate() {
        ctx.fillStyle = "rgba(0, 0, 0, 0.2)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        fireworks.forEach((firework) => {
          firework.update();
          firework.draw();
        });

        requestAnimationFrame(animate);
      }

      // Generate fireworks at intervals
      setInterval(() => {
        createRandomFirework();
      }, 1000);

      animate();
    },
  }
};
</script>

<style scoped>
canvas.message-canvas {
  position: fixed; /*设置定位*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0e1729; /*画布背景色*/
}
.message-banner {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  height: 100vh;
  //background: #49b1f5 url(https://www.talk-ny.cn/d5ojdj.jpg) no-repeat center center;
  animation: header-effect 1s;
}
.message-title {
  color: #eee;
  animation: title-scale 1s;
}
.message-container {
  position: absolute;
  width: 360px;
  top: 35%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 5;
  margin: 0 auto;
  color: #fff;
}
.message-input-wrapper {
  display: flex;
  justify-content: center;
  height: 2.5rem;
  margin-top: 2rem;
}
.message-input-wrapper input {
  outline: none;
  width: 70%;
  border-radius: 20px;
  height: 100%;
  padding: 0 1.25rem;
  color: #eee;
  border: #fff 1px solid;
}
.message-input-wrapper input::-webkit-input-placeholder {
  color: #eeee;
}
.message-input-wrapper button {
  outline: none;
  border-radius: 20px;
  height: 100%;
  padding: 0 1.25rem;
  border: #fff 1px solid;
}
.barrage-container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% -50px);
  width: 100%;
}
</style>

<template>
  <v-dialog v-model="loginFlag" :fullscreen="isMobile" max-width="460" persisitent="true">
    <v-card class="login-container" style="border-radius:4px">
      <v-icon class="float-right" @click="loginFlag = false">mdi-close</v-icon>
      <div class="login-wrapper">
        <!-- 用户名 -->
        <v-text-field v-model="username" label="邮箱号" placeholder="请输入您的邮箱号" clearable @keyup.enter="login"/>
        <!-- 密码 -->
        <v-text-field v-model="password" class="mt-7" label="密码" placeholder="请输入您的密码" @keyup.enter="login" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show"/>
        <!-- 验证码 -->
        <v-text-field v-if="captchaEnabled" v-model="code" class="mt-7 login-code-text" label="验证码" placeholder="请输入验证码" @keyup.enter="login"/>
        <div class="mt-7 login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img" alt=""/>
        </div>
        <!-- 按钮 -->
        <v-btn class="mt-7" block color="blue" style="color:#fff" @click="login">登录</v-btn>
        <!-- 注册和找回密码 -->
        <div class="mt-10 login-tip">
          <span @click="openRegister">立即注册</span>
          <span @click="openForget" class="float-right">忘记密码?</span>
        </div>
        <div class="social-login-title">社交账号登录</div>
        <div class="social-login-wrapper">
          <!-- 微博登录 -->
          <a class="mr-3 iconfont icon-weibo" style="color:#e05244" @click="weiboLogin">&nbsp;</a>
          <!-- qq登录 -->
          <a class="mr-3 iconfont icon-qq" style="color:#00AAEE" @click="qqLogin">&nbsp;</a>
          <!-- 百度登录 -->
          <a class="mr-3 iconfont icon-circular-baidu" style="color:#1D2FE3" @click="baiduLogin">&nbsp;</a>
          <!-- 微信登录 -->
          <!-- <a class="mr-3 iconfont icon-weixin-copy" style="color:#3FB918" @click="wechatLogin" >&nbsp;</a>-->
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCodeImg } from "@/api/login";
import { encrypt, decrypt } from "@/utils/jsencrypt";
export default {
  data: function() {
    return {
      captchaEnabled: true,
      username: "",
      password: "",
      code: "",
      uuid: "",
      show: false,
      codeUrl: ""
    };
  },
  computed: {
    loginFlag: {
      set(value) {
        this.$store.dispatch('blog/setLoginFlag',value);
      },
      get() {
        console.log("loginFlag",this.$store.state.blog.loginFlag)
        if (this.$store.state.blog.loginFlag) {
          this.getCode();
        }
        return this.$store.state.blog.loginFlag;
      }
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      return clientWidth <= 960;
    }
  },
  created() {
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled =
          res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      if (this.$cookie.get("username") && this.$cookie.get("password")) {
        this.username = this.$cookie.get("username");
        this.password = decrypt(this.$cookie.get("password"));
        //this.toLogin();
      }
    },
    openRegister() {
      this.$store.dispatch('blog/setLoginFlag',false);
      this.$store.dispatch('blog/setRegisterFlag',true);
    },
    openForget() {
      this.$store.dispatch('blog/setLoginFlag',false);
      this.$store.dispatch('blog/setForgetFlag',true);
    },
    toLogin() {
      const that = this;
      const loginForm={
        username:that.username,
        password:that.password,
        code:that.code,
        uuid:that.uuid
      }
      this.$store.dispatch("Login", loginForm).then(() => {
        this.$cookie.set("username", that.username, 7);
        this.$cookie.set("password", encrypt(that.password), 7);
        that.username = "";
        that.password = "";
        that.$store.dispatch('blog/setLoginFlag', false);
        that.$toast({ type: "success", message: "登录成功" });
      }).catch(() => {
        if (this.captchaEnabled) {
          this.getCode();
        }
      });
    },
    login() {
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(this.username)) {
        this.$toast({ type: "error", message: "邮箱格式不正确" });
        return false;
      }
      if (this.password.trim().length === 0) {
        this.$toast({ type: "error", message: "密码不能为空" });
        return false;
      }
      if (this.captchaEnabled && this.code.trim().length === 0) {
        this.$toast({ type: "error", message: "验证码不能为空" });
        return false;
      }
      //发送登录请求
      this.toLogin();
    },
    qqLogin() {
      //保留当前路径
      this.$store.dispatch("SaveLoginUrl", this.$route.path).then(res => {
        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)) {
            // eslint-disable-next-line no-undef
            QC.Login.showPopup({
                appId: this.config.QQ_APP_ID,
                redirectURI: this.config.QQ_REDIRECT_URI
            });
        } else {
            window.open(
                "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=" +
                +this.config.QQ_APP_ID +
                "&response_type=token&scope=all&redirect_uri=" +
                this.config.QQ_REDIRECT_URI,
                "_self"
            );
        }
      }).catch(() => {});
    },
    weiboLogin() {
      //保留当前路径
      this.$store.dispatch("SaveLoginUrl",  this.$route.path).then(res =>{
        window.open(
          "https://api.weibo.com/oauth2/authorize?client_id=" +
            this.config.WEIBO_APP_ID +
            "&response_type=code&redirect_uri=" +
            this.config.WEIBO_REDIRECT_URI,
          "_self"
        );
      }).catch(() => {});
    },
    baiduLogin() {
      //保留当前路径
      this.$store.dispatch("SaveLoginUrl",  this.$route.path).then(res =>{
        window.open(
          "https://openapi.baidu.com/oauth/2.0/authorize?client_id=" +
            this.config.BAIDU_APP_KEY +
            "&response_type=code&redirect_uri=" +
            this.config.BAIDU_REDIRECT_URI,
          "_self"
        );
      }).catch(() => {});
    },
    wechatLogin() {
      //保留当前路径
      this.$store.dispatch("SaveLoginUrl", this.$route.path).then(res =>{
        window.open(
          "https://open.weixin.qq.com/connect/qrconnect?appid=" +
            this.config.WECHAT_APP_KEY +
            "&redirect_uri=" +
            this.config.WECHAT_REDIRECT_URI +
            "&response_type=code&scope=snsapi_login",
          "_self"
        );
      }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.login-container {
  padding: 1rem;
  border-radius: 4px;
  height: 540px;
}
.social-login-title {
  margin-top: 1.5rem;
  color: #b5b5b5;
  font-size: 0.75rem;
  text-align: center;
}

.social-login-title::before {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}

.social-login-title::after {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}

.social-login-wrapper {
  margin-top: 1rem;
  font-size: 2rem;
  text-align: center;
}

.social-login-wrapper a {
  text-decoration: none;
}
.login-code-text {
  float: left;
  width: 66%;
}

.login-code {
  width: 33%;
  height: 46px;
  float: right;
  text-align: right;
}
.login-code img {
  cursor: pointer;
  vertical-align: bottom;
}

.login-code-img {
  height: 46px;
}
</style>
